import React from "react";
import { useSelector } from "react-redux";
import { checkPermissions, checkRoles } from "../../../utils/helper";

const Component = ({ roles, permissions, type, children }) => {
  const user = useSelector((state) => state.auth.user);
  let isAllowed = false;

  if (type === "roles") {
    isAllowed = checkRoles(roles, user);
  }

  if (type === "permissions") {
    isAllowed = checkPermissions(permissions, user.permissions);
  }

  if (isAllowed) {
    return children;
  }
  return <></>;
};

Component.defaultProps = {
  roles: [],
  permissions: [],
  type: "permissions",
};
export default Component;
