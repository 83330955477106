import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  Modal,
  notification,
} from "antd";
import moment from "moment";
import axios from "axios";
import URL_STRING from "../../mockData/data";
import { useSelector } from "react-redux";

function Booking({
  data,
  setShowAddModal,
  showAddModal,
  fetchData,
  currentEvents,
}) {
  const authState = useSelector((state) => state.auth);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState([]);
  const [date, setDate] = useState(null);
  const [court, setCourt] = useState("court1");

  useEffect(() => {
    if (data?.from) {
      setDate(moment(data.from));

      if (data.allDay) {
        setTime([moment().hour(8).minute(0), moment().hour(10).minute(0)]);
      } else {
        setTime([moment(data.from), moment(data.to)]);
      }
    }
  }, [data]);

  function onChangeTime(time, timeString) {
    if (date) {
      const timeArray = [];
      time.forEach((t) => {
        timeArray.push(t.date(date.date()));
      });
      setTime(timeArray);
    } else {
      setTime(time);
    }
  }

  function onChangeDate(date, dateString) {
    if (time.length > 0) {
      const timeArray = [];

      time.forEach((t) => {
        timeArray.push(t.date(date.date()));
      });
      setTime(timeArray);
    }
    setDate(date);
  }

  const format = "HH:mm";

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const submitDataEvent = () => {
    const data = {
      id: Math.ceil(Math.random() * 100000),
      title: name,
      email,
      phone,
      date: date.format("YYYY-MM-DD"),
      start: date.format("YYYY-MM-DD") + `T${time[0].format("HH:mm")}:00`,
      end: date.format("YYYY-MM-DD") + `T${time[1].format("HH:mm")}:00`,
      className: court,
      cid: court,
      status: authState.user.role === "admin" ? 1 : 0,
    };

    const eventArray = [];

    currentEvents.forEach((e) => {
      if (e.cid === court && e.date === date.format("YYYY-MM-DD")) {
        eventArray.push(e);
      }
    });

    let duplicate = false;

    eventArray.forEach((e) => {
      if (
        moment(e.start).isSameOrBefore(time[0]) &&
        moment(e.end).isSameOrAfter(time[1])
      ) {
        duplicate = true;
      }

      if (time[0].isAfter(moment(e.start)) && time[0].isBefore(moment(e.end))) {
        duplicate = true;
      }

      if (time[1].isAfter(moment(e.start)) && time[1].isBefore(moment(e.end))) {
        duplicate = true;
      }

      if (
        time[0].isSameOrBefore(moment(e.start)) &&
        time[1].isSameOrAfter(moment(e.end))
      ) {
        duplicate = true;
      }
    });

    console.log(duplicate);

    if (!duplicate) {
      // dispatch(addData(data));
      axios.post(URL_STRING + "/insert", data).then((res) => {
        console.log(res.data);
        console.log("hahaha");
        fetchData();
      });
      openNotificationWithIcon("success", "Success", "Event has been added");
    } else {
      openNotificationWithIcon(
        "error",
        "Error",
        "This court is already booked at this particular time"
      );
    }

    closeAddModal();
  };

  function closeAddModal() {
    setName("");
    setEmail("");
    setPhone("");
    setTime([]);
    setDate(null);
    setCourt("court1");
    setShowAddModal(false);
  }

  return (
    <Modal
      title="Add Booking"
      visible={showAddModal}
      width={800}
      onCancel={() => {
        closeAddModal();
      }}
      footer={[
        <Button onClick={() => closeAddModal()} key="cancel">
          Cancel
        </Button>,
        <Button
          disabled={
            name === "" ||
            time.length === 0 ||
            date === null ||
            email === "" ||
            phone === ""
          }
          key="submit"
          type="primary"
          onClick={submitDataEvent}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout="horizontal"
      >
        <Form.Item label="Name">
          <Input
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Title"
          />
        </Form.Item>
        <Form.Item label="Email">
          <Input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item label="Phone">
          <Input
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="Phone"
          />
        </Form.Item>
        <Form.Item label="Court / Field">
          <Select value={court} onChange={(e) => setCourt(e)}>
            <Select.Option value="court1">Court 1</Select.Option>
            <Select.Option value="court2">Court 2</Select.Option>
            <Select.Option value="court3">Court 3</Select.Option>
            <Select.Option value="court4">Court 4</Select.Option>
            <Select.Option value="court5">Field</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Time">
          <DatePicker onChange={onChangeDate} value={date} />
          <TimePicker.RangePicker
            minuteStep={30}
            showSecond={false}
            style={{ marginLeft: "2rem" }}
            format={format}
            onChange={onChangeTime}
            value={time}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Booking;
