import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, DatePicker } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Line } from "@ant-design/charts";
import axios from "axios";
import URL_STRING from "../../mockData/data";

function BookingAnalysis() {
  const [eventData, setEventData] = useState([]);

  const location = useLocation();

  const path = location.pathname.split("/")[1];

  var config = {
    data: eventData,
    xField: "date",
    yField: "value",
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return "".concat(s, ",");
          });
        },
      },
    },
    seriesField: "type",
    color: function color(_ref) {
      var type = _ref.type;
      return type === "total"
        ? "rgba(0, 0, 0, 0.5)"
        : type === "court1"
        ? "#f0cde8"
        : type === "court2"
        ? "#c1f6c1"
        : type === "court3"
        ? "#c6daff"
        : type === "court4"
        ? "#ffcccc"
        : "#d9ff00";
    },
  };

  const [selectedMonth, setSelectedMonth] = useState(moment());

  useEffect(() => {
    const event_array = [];

    const calculate_date_total = [];
    const calculate_date_court1 = [];
    const calculate_date_court2 = [];
    const calculate_date_court3 = [];
    const calculate_date_court4 = [];
    const calculate_date_court5 = [];

    for (let i = 1; i < 32; i++) {
      const month = selectedMonth.format("YYYY-MM");

      calculate_date_total.push({
        date: `${month}-${i}`,
        type: "total",
        value: 0,
      });

      calculate_date_court1.push({
        date: `${month}-${i}`,
        type: "court1",
        value: 0,
      });

      calculate_date_court2.push({
        date: `${month}-${i}`,
        type: "court2",
        value: 0,
      });

      calculate_date_court3.push({
        date: `${month}-${i}`,
        type: "court3",
        value: 0,
      });

      calculate_date_court4.push({
        date: `${month}-${i}`,
        type: "court4",
        value: 0,
      });

      calculate_date_court5.push({
        date: `${month}-${i}`,
        type: "field",
        value: 0,
      });
    }

    axios.get(URL_STRING).then((res) => {
      res.data.forEach((i) => {
        const event_split = i.start.split("-");

        const currentMonth = selectedMonth.format("MM");

        if (event_split[1] === currentMonth) {
          event_array.push(i);
          const date_event = parseInt(event_split[2].split("T")[0]);
          calculate_date_total[date_event - 1].value++;

          switch (i.cid) {
            case "court1":
              calculate_date_court1[date_event - 1].value++;
              break;
            case "court2":
              calculate_date_court2[date_event - 1].value++;
              break;
            case "court3":
              calculate_date_court3[date_event - 1].value++;
              break;
            case "court4":
              calculate_date_court4[date_event - 1].value++;
              break;
            case "court5":
              calculate_date_court5[date_event - 1].value++;
              break;

            default:
              break;
          }
        }
      });

      setEventData([
        ...calculate_date_total,
        ...calculate_date_court1,
        ...calculate_date_court2,
        ...calculate_date_court3,
        ...calculate_date_court4,
        ...calculate_date_court5,
      ]);
    });
  }, [selectedMonth, path]);

  const date_array = [];

  for (let i = 1; i < 32; i++) {
    date_array.push(i);
  }

  function onChange(date, dateString) {
    setSelectedMonth(date);
  }

  return (
    <div
      className="site-layout-background"
      style={{
        width: "90%",
        margin: "auto",
        minHeight: "100vh",
        padding: "2rem",
      }}
    >
      <Row
        style={{
          padding: "1rem 0.2rem",
          backgroundColor: "#fbfbfb",
          border: "1px solid #d9d9d9",
          borderRadius: "2px",
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <h2>Booking Analysis</h2>
        </Col>
      </Row>
      <section
        style={{
          minHeight: `calc(100vh - 135px)`,
          padding: "1rem",
          margin: "auto",
        }}
      >
        <Row style={{ padding: "0.5rem" }} justify="space-around">
          <Col>
            <h2 style={{ marginBottom: 0 }}>
              {selectedMonth.format("MMMM YYYY")}
            </h2>
          </Col>
          <Col>
            <DatePicker
              defaultValue={selectedMonth}
              onChange={onChange}
              picker="month"
            />
          </Col>
        </Row>
        <Row style={{ padding: "1rem" }}>
          <Col span={24}>
            <Line height={500} {...config} />
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default BookingAnalysis;
