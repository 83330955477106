import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Radio, DatePicker } from "antd";
import moment from "moment";
import { Column, Line } from "@ant-design/charts";
import styles from "./IncidentCount.module.css";
import { peopleCountServices } from "../../../service/report/safetyMonitor";
import { useSelector } from "react-redux";
import { getDefValue } from "../../../utils/helper";
import {
  graphConfig,
  paramsBuilder,
  generateDefGraph,
  generateData,
} from "../../../utils/graphHelper";
let onlyData = ["Exit", "Entry"];

function SoccerField() {
  const peopleCountState = useSelector((state) => state.peopleCount);

  const [graphType, setGraphType] = useState("bar");
  const [graphTime, setGraphTime] = useState("week");
  const [dateValue, setDateValue] = useState(moment());

  let width = window.innerWidth;
  let mediumScreen = false;

  if (width > 767) {
    mediumScreen = true;
  }

  let configCombined;

  configCombined = graphConfig(graphType);
  configCombined.data = peopleCountState.data;

  const isLoading = peopleCountState.isLoading;

  const prepData = useCallback(() => {
    const params = paramsBuilder(
      graphTime,
      dateValue,
      "c64616c9-1cbf-48d2-b62f-bc674de4a01a"
    );
    peopleCountServices.getDataOnly(params, (data) => {
      const ress = getDefValue(data, "incidentCounts", []);
      const dt = generateData(ress, graphTime, true, onlyData);

      const dt2 = generateDefGraph(dt, graphTime, dateValue);
      setDataGraph(dt2);
    });
  }, [graphTime, dateValue]);

  const setDataGraph = (data) => {
    peopleCountServices.setData(data);
  };

  const mainInterval = useRef();

  useEffect(() => {
    clearInterval(mainInterval.current);
    prepData();
  }, [prepData]);

  useEffect(() => {
    mainInterval.current = setInterval(() => {
      console.log("Soccer Field update");
      const params = paramsBuilder(
        graphTime,
        dateValue,
        "c64616c9-1cbf-48d2-b62f-bc674de4a01a"
      );
      peopleCountServices.getDataOnly(params, (data) => {
        const ress = getDefValue(data, "incidentCounts", []);
        const dt = generateData(ress, graphTime, true, onlyData);

        const dt2 = generateDefGraph(dt, graphTime, dateValue);
        setDataGraph(dt2);
      });
    }, 10000);

    return () => {
      clearInterval(mainInterval.current);
    };
  }, [dateValue, graphTime]);

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const showGraph = () => {
    if (graphType === "bar") {
      return (
        <Column loading={isLoading} {...configCombined} animation={false} />
      );
    } else {
      return <Line loading={isLoading} {...configCombined} animation={false} />;
    }
  };

  return (
    <React.Fragment>
      <Row style={{ padding: "3rem 0" }}>
        <Col
          md={15}
          xs={24}
          offset={mediumScreen ? 3 : 0}
          style={mediumScreen ? "" : { marginBottom: "1rem" }}
        >
          {showGraph()}
        </Col>
        <Col
          md={6}
          xs={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <DatePicker
            onChange={(e) => {
              setDateValue(e);
            }}
            picker={graphTime}
            value={dateValue}
            disabled={isLoading}
            disabledDate={disabledDate}
          />
          <Radio.Group
            onChange={(e) => {
              setGraphTime(e.target.value);
            }}
            value={graphTime}
            style={mediumScreen ? null : { marginBottom: "1rem" }}
            disabled={isLoading}
          >
            {/* <Radio.Button className={styles.radioButton} value="day">
              Day
            </Radio.Button> */}
            <Radio.Button className={styles.radioButton} value="week">
              Week
            </Radio.Button>
            <Radio.Button className={styles.radioButton} value="month">
              Month
            </Radio.Button>
            <Radio.Button className={styles.radioButton} value="year">
              Year
            </Radio.Button>
          </Radio.Group>
          <Radio.Group
            onChange={(e) => {
              setGraphType(e.target.value);
            }}
            defaultValue="bar"
            disabled={isLoading}
          >
            <Radio.Button className={styles.radioButton} value="bar">
              Bar
            </Radio.Button>
            <Radio.Button className={styles.radioButton} value="line">
              Line
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default SoccerField;
