import { Button, Col, Modal, Row } from "antd";
import { Can } from "../Auth";
import { PERMISSIONS } from "../../config/const";

function BookingInfo({
  showInfoModal,
  setShowInfoModal,
  setShowEditEventModal,
  setShowDeleteModal,
  eventInfoData,
}) {
  return (
    <Modal
      title="Event Info"
      width={600}
      visible={showInfoModal}
      onOk={() => {
        setShowInfoModal(false);
      }}
      onCancel={() => setShowInfoModal(false)}
      okText="OK"
      cancelText="Cancel"
      footer={[
        <Can permissions={[PERMISSIONS.BOOKING_EDIT_EVENT]}>
          <Button
            onClick={() => setShowEditEventModal(true)}
            key="edit"
            type="primary"
          >
            Edit
          </Button>
        </Can>,
        <Can permissions={[PERMISSIONS.BOOKING_EDIT_EVENT]}>
          <Button
            onClick={() => setShowDeleteModal(true)}
            key="delete"
            type="primary"
          >
            Delete
          </Button>
        </Can>,
        <Button onClick={() => setShowInfoModal(false)} key="ok" type="primary">
          OK
        </Button>,
      ]}
    >
      {eventInfoData.map((e) => (
        <Row gutter={[16, 16]} style={{ padding: "0.5rem 0" }} key={e.key}>
          <Col span={4} offset={4} style={{ textAlign: "right" }}>
            {e.item} :
          </Col>
          <Col span={16}>{e.value !== "Court 5" ? e.value : "Field"}</Col>
        </Row>
      ))}
    </Modal>
  );
}

export default BookingInfo;
