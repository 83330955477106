export const ROLES = {
  ADMIN: "admin",
  USER: "user",
  USERDEMO: "demo",
};

export const DATA_CONFIG_API_ONLY = 1;
export const DATA_CONFIG_DUMMY_ONLY = 2;
export const DATA_CONFIG_COMBO = 3;

export const PERMISSIONS = {
  OPEN_CALENDAR: "OPEN_CALENDAR",
  OPEN_BOOKING_ANALYLSIS: "OPEN_BOOKING_ANALYLSIS",
  BOOKING_EDIT_EVENT: "BOOKING_EDIT_EVENT",
  BOOKING_DELETE_EVENT: "BOOKING_DELETE_EVENT",
};
