const devices = [
  {
    DeviceId: "IRC",
    DeviceMetadata: {
      DeviceName: "Indian Recreation Club",
      DeviceLocation: {
        DeviceCoordinates: {
          Latitude: 22.27541,
          Longitude: 114.18871,
        },
      },
    },
    DeviceStatus: {
      ConnectionStatus: "Online",
    },
  },
];

export { devices };
