import React, { useState, useEffect } from "react";
import { Row, Col, Radio, DatePicker } from "antd";
import moment from "moment";
import { Column, Line } from "@ant-design/charts";
import energyCount from "../../../mockData/energyCount";
import styles from "./EnergyCount.module.css";
import { FilePptOutlined } from "@ant-design/icons";
import axios from "axios";
import URL_STRING from "../../../mockData/data";

function EnergyCount() {
  const [graphType] = useState("line");
  const [graphTime, setGraphTime] = useState("year");
  const [weekValue, setWeekValue] = useState(moment());
  const [energyData, setEnergyData] = useState([]);

  useEffect(() => {
    axios.get(URL_STRING + "/energy").then((res) => {
      console.log("res.data", res.data);
      const array = [];

      res.data.forEach((e) => {
        if (moment(e.date).isBefore(moment().format("YYYY-MM"))) {
          array.push({
            date: e.date + "-1",
            name: "Energy Consumption (kwh)",
            count: e.energyOnBillThisYear,
          });

          array.push({
            date: e.date + "-1",
            name: "Energy Saving (kwh)",
            count: e.monthlySaving,
          });
        }
      });

      console.log(array);

      setEnergyData(array);
    });
  }, []);

  let width = window.innerWidth;
  let mediumScreen = false;

  if (width > 767) {
    mediumScreen = true;
  }

  const {
    weeklyDataEnergyCount,
    monthlyDataEnergyCount,
    yearlyDataEnergyCount,
  } = energyCount;

  console.log(yearlyDataEnergyCount);

  let configCombined;

  if (graphType === "bar") {
    configCombined = {
      data: weeklyDataEnergyCount.peopleInCount.data.concat(
        weeklyDataEnergyCount.peopleOutCount.data
      ),
      isGroup: true,
      xField: "date",
      xAxis: {
        label: {
          formatter: function formatter(v) {
            if (graphTime === "year") return moment(v).format("MMM YY");
            else return moment(v).format("DD MMM YY");
          },
        },
      },
      height: mediumScreen ? 350 : 200,
      yField: "count",
      seriesField: "name",
      label: {
        position: "middle",
        layout: [
          {
            type: "interval-adjust-position",
          },
          {
            type: "interval-hide-overlap",
          },
          {
            type: "adjust-color",
          },
        ],
      },
      color: function color(_ref) {
        var name = _ref.name;
        return name === "Energy Consumption (kwh)" ? "#5b8ff9" : "#5ad8a6";
      },
    };
  } else if (graphType === "line") {
    configCombined = {
      data: weeklyDataEnergyCount.peopleInCount.data.concat(
        weeklyDataEnergyCount.peopleOutCount.data
      ),
      xField: "date",
      xAxis: {
        label: {
          formatter: function formatter(v) {
            if (graphTime === "year") return moment(v).format("MMM YY");
            else return moment(v).format("DD MMM YY");
          },
        },
      },
      height: mediumScreen ? 350 : 200,
      yField: "count",
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return "".concat(s, ",");
            });
          },
        },
      },
      seriesField: "name",
      color: function color(_ref) {
        var name = _ref.name;
        return name === "Energy Consumption (kwh)" ? "#5b8ff9" : "#5ad8a6";
      },
      lineStyle: function lineStyle(_ref2) {
        var name = _ref2.name;
        if (name === "Energy Consumption (kwh)") {
          return {
            // lineDash: [4, 4],
            opacity: 1,
          };
        }
        return { opacity: 0.5 };
      },
    };
  }

  const graph = () => {
    const combinedData = yearlyDataEnergyCount.peopleInCount.data.concat(
      yearlyDataEnergyCount.peopleOutCount.data
    );

    const formattedDate = moment(weekValue).format("YYYY");

    configCombined.data = energyData.filter((item) => {
      return moment(item.date).format("YYYY") === formattedDate;
    });

    if (graphType === "bar") {
      return <Column {...configCombined} />;
    } else if (graphType === "line") {
      return <Line {...configCombined} />;
    }
  };

  function onChangeTime(e) {
    setGraphTime(e.target.value);
  }

  function onChangeWeek(date, dateString) {
    setWeekValue(date);
  }

  return (
    <React.Fragment>
      <Row style={{ height: 70 }}>
        <Col offset={mediumScreen ? 1 : 0}>
          <span
            style={{
              display: "block",
              fontSize: "18px",
              paddingLeft: "2rem",
              paddingTop: "2rem",
              paddingBottom: "0",
              fontWeight: 600,
            }}
          >
            <FilePptOutlined style={{ paddingRight: "1rem" }} />
            Report
          </span>
        </Col>

        <Col offset={mediumScreen ? 12 : 0}>
          <span
            style={{
              display: "block",
              fontSize: "18px",
              paddingLeft: "2rem",
              paddingTop: "2rem",
              paddingBottom: "0",
              fontWeight: 600,
            }}
          >
            {/* <DatePicker
                                picker="month"
                                
                                 size={mediumScreen ? "default" : "small"}
                                 // onChange={onChangeWeek}
                                 // defaultValue={moment(weekValue)}
                                 format="MMMM YYYY"
                                 style={{
                                     width: "100%",
                                     fontWeight: 700
                                 }}
                            /> */}
          </span>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: "1rem",
          paddingBottom: "3rem",
          paddingRight: "1.3rem",
          paddingLeft: "1rem",
        }}
        gutter={[16, 16]}
      >
        <Col
          md={17}
          xs={24}
          offset={mediumScreen ? 2 : 0}
          style={
            mediumScreen
              ? ""
              : {
                  marginBottom: "1rem",
                }
          }
        >
          {graph()}
        </Col>
        <Col
          md={5}
          xs={24}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              padding: mediumScreen ? 0 : "1rem 0",
              fontWeight: 600,
            }}
          >
            <DatePicker
              picker={graphTime}
              size={mediumScreen ? "default" : "small"}
              onChange={onChangeWeek}
              // defaultValue={moment(weekValue)}
              value={weekValue}
              format={graphTime === "month" ? "MMM YYYY" : "YYYY"}
              style={{
                width: "100%",
                fontWeight: 700,
              }}
            />
          </span>
          {/* <Radio.Group
            onChange={onChangeTime}
            defaultValue="week"
            style={
              mediumScreen
                ? { flexDirection: "inherit" }
                : {
                    marginBottom: "1rem",
                    flexDirection: "inherit",
                  }
            }
          >
            <Radio.Button className={styles.radioButton} value="month">
              Month
            </Radio.Button>
            <Radio.Button className={styles.radioButton} value="year">
              Year
            </Radio.Button>
          </Radio.Group> */}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default EnergyCount;
