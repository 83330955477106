import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import styles from "./List.module.css";
import moment from "moment";
import axios from "axios";
import URL_STRING from "../../../mockData/data";

function List() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const month = moment().subtract(1, "month").format("YYYY-MM");
    axios
      .get(URL_STRING + "/energy?date=" + month)
      // .get("http://localhost:5000/energy?date=" + month)
      .then((res) => {
        if (res.data.length === 0) {
          const month = moment().subtract(2, "month").format("YYYY-MM");
          axios
            .get(URL_STRING + "/energy?date=" + month)
            // .get("http://localhost:5000/energy?date=" + month)
            .then((res) => {
              console.log(res.data);
              setData(res.data[0]);
            });
        } else {
          setData(res.data[0]);
        }
      });
  }, []);

  return (
    <React.Fragment>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col>
          <span className={styles.title}>
            <UnorderedListOutlined
              style={{
                paddingRight: "1rem",
              }}
            />
            List
          </span>
        </Col>
        <Col>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>% of IRCP Energy Consumtion</td>
                <td>{data.monthlyPercentage} %</td>
              </tr>
              <tr>
                <td>Last Month Estimated Consumption</td>
                <td>{data.energyOnDevice} KWh</td>
              </tr>
              <tr>
                <td>Last Month Estimated Savings</td>
                <td>{data.monthlySaving} KWh</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default List;
