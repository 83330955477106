import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Row,
  Col,
  Switch,
  Typography,
  Modal,
  Button,
  notification,
} from "antd";
import BookingInfo from "../../components/BookingInfo";
import AddBooking from "../../components/AddBooking";
import EditBooking from "../../components/EditBooking";
import moment from "moment";
import axios from "axios";
import URL_STRING from "../../mockData/data";

const DemoApp = () => {
  const [currentEvents, setCurrentEvents] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [eventInfoData, setEventInfoData] = useState([]);
  const [deleteModalText, setDeleteModalText] = useState("");
  const [eventInfo, setEventInfo] = useState({});
  const [addModalInfo, setAddModalInfo] = useState({});

  const [court1, setCourt1] = useState(true);
  const [court2, setCourt2] = useState(true);
  const [court3, setCourt3] = useState(true);
  const [court4, setCourt4] = useState(true);
  const [court5, setCourt5] = useState(true);

  const { Title, Text } = Typography;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [court1, court2, court3, court4, court5]);

  const fetchData = () => {
    axios.get(URL_STRING).then((res) => {
      console.log(res.data);

      const event_array = [];
      res.data.forEach((i) => {
        if (court1) {
          if (i.cid === "court1") {
            event_array.push({ ...i, className: i.cid });
          }
        }
        if (court2) {
          if (i.cid === "court2") {
            event_array.push({ ...i, className: i.cid });
          }
        }
        if (court3) {
          if (i.cid === "court3") {
            event_array.push({ ...i, className: i.cid });
          }
        }
        if (court4) {
          if (i.cid === "court4") {
            event_array.push({ ...i, className: i.cid });
          }
        }
        if (court5) {
          if (i.cid === "court5") {
            event_array.push({ ...i, className: i.cid });
          }
        }
      });
      setCurrentEvents(event_array);
    });
  };

  const renderSidebar = () => {
    return (
      <div className="demo-app-sidebar">
        <Row style={{ padding: "1rem 0" }} justify="space-between">
          <Col>
            <Title style={{ marginBottom: 0 }}>Calendar</Title>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                setAddModalInfo({});
                setShowAddModal(true);
              }}
            >
              Add Booking
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            padding: "1rem 0 2rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Row>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Switch
                defaultChecked
                className={court1 ? "switch_court1__active" : "switch_court1"}
                onChange={onChangeCourt1}
              />
              <Text style={{ paddingLeft: "0.5rem" }}>Court 1</Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <Switch
                defaultChecked
                className={court2 ? "switch_court2__active" : "switch_court2"}
                onChange={onChangeCourt2}
              />
              <Text style={{ paddingLeft: "0.5rem" }}>Court 2</Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <Switch
                defaultChecked
                className={court3 ? "switch_court3__active" : "switch_court3"}
                onChange={onChangeCourt3}
              />
              <Text style={{ paddingLeft: "0.5rem" }}>Court 3</Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <Switch
                defaultChecked
                className={court4 ? "switch_court4__active" : "switch_court4"}
                onChange={onChangeCourt4}
              />
              <Text style={{ paddingLeft: "0.5rem" }}>Court 4</Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <Switch
                defaultChecked
                className={court5 ? "switch_court5__active" : "switch_court5"}
                onChange={onChangeCourt5}
              />
              <Text style={{ paddingLeft: "0.5rem" }}>Field</Text>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: "0 0.4rem" }}>
              <span
                style={{
                  width: 0,
                  height: 0,
                  border: "5px solid black",
                  borderColor: "#cc9900",
                  display: "inline-block",
                  boxSizing: "content-box",
                  borderRadius: "10px",
                }}
              />
              <p
                style={{
                  marginBottom: 0,
                  display: "inline-block",
                  marginLeft: "0.5rem",
                }}
              >
                New
              </p>
            </Col>
            <Col style={{ padding: "0 0.4rem" }}>
              <span
                style={{
                  width: 0,
                  height: 0,
                  border: "5px solid black",
                  borderColor: "#0033ff",
                  display: "inline-block",
                  boxSizing: "content-box",
                  borderRadius: "10px",
                }}
              />
              <p
                style={{
                  marginBottom: 0,
                  display: "inline-block",
                  marginLeft: "0.5rem",
                }}
              >
                Approved
              </p>
            </Col>
            <Col style={{ padding: "0 0.4rem" }}>
              <span
                style={{
                  width: 0,
                  height: 0,
                  border: "5px solid black",
                  borderColor: "rgb(42, 133, 3)",
                  display: "inline-block",
                  boxSizing: "content-box",
                  borderRadius: "10px",
                }}
              />
              <p
                style={{
                  marginBottom: 0,
                  display: "inline-block",
                  marginLeft: "0.5rem",
                }}
              >
                Checked In
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  const onChangeCourt1 = (checked) => {
    setCourt1(!court1);
  };

  const onChangeCourt2 = (checked) => {
    setCourt2(!court2);
  };

  const onChangeCourt3 = (checked) => {
    setCourt3(!court3);
  };

  const onChangeCourt4 = (checked) => {
    setCourt4(!court4);
  };

  const onChangeCourt5 = (checked) => {
    setCourt5(!court5);
  };

  const handleDateSelect = (selectInfo) => {
    setAddModalInfo({
      from: selectInfo.startStr.split("+")[0],
      to: selectInfo.endStr.split("+")[0],
      allDay: selectInfo.allDay,
    });
    setShowAddModal(true);
  };

  const handleEventClick = (clickInfo) => {
    const startTime = moment(clickInfo.event.startStr.split("+")[0]);
    const endTime = moment(clickInfo.event.endStr.split("+")[0]);

    let court = clickInfo.event.extendedProps.cid;

    switch (court) {
      case "court1":
        court = "Court 1";
        break;
      case "court2":
        court = "Court 2";
        break;
      case "court3":
        court = "Court 3";
        break;
      case "court4":
        court = "Court 4";
        break;
      case "court5":
        court = "Court 5";
        break;

      default:
        break;
    }

    setEventInfoData([
      {
        key: "1",
        item: "Title",
        value: clickInfo.event.title,
      },
      {
        key: "2",
        item: "Email",
        value: clickInfo.event.extendedProps.email,
      },
      {
        key: "3",
        item: "Phone",
        value: clickInfo.event.extendedProps.phone,
      },
      {
        key: "4",
        item: "Date",
        value: startTime.format("dddd, D MMMM YYYY"),
      },
      {
        key: "5",
        item: "Start time",
        value: startTime.format("HH:mm"),
      },
      {
        key: "6",
        item: "End time",
        value: endTime.format("HH:mm"),
      },
      {
        key: "7",
        item: "Court",
        value: court,
      },
      {
        key: "8",
        item: "Status",
        value:
          clickInfo.event.extendedProps.status === 0
            ? "New"
            : clickInfo.event.extendedProps.status === 1
            ? "Approved"
            : "Checked In",
      },
    ]);

    setShowInfoModal(true);
    setDeleteModalText(
      `Are you sure you want to delete the event ${clickInfo.event.title}?`
    );
    setEventInfo(clickInfo);
  };

  const eventOnDrag = (evt) => {
    const data = {
      id: evt.event.id,
      title: evt.event.title,
      phone: evt.event.extendedProps.phone,
      email: evt.event.extendedProps.email,
      start:
        moment(evt.event.startStr).format("YYYY-MM-DD") +
        `T${moment(evt.event.startStr).format("HH:mm")}:00`,
      end:
        moment(evt.event.endStr).format("YYYY-MM-DD") +
        `T${moment(evt.event.endStr).format("HH:mm")}:00`,
      className: evt.event.extendedProps.cid,
      cid: evt.event.extendedProps.cid,
    };

    const eventArray = [];

    currentEvents.forEach((e) => {
      if (
        e.cid === data.cid &&
        e.date === moment(evt.event.startStr).format("YYYY-MM-DD") &&
        e.id !== parseInt(evt.event.id)
      ) {
        eventArray.push(e);
      }
    });

    let duplicate = false;

    const time = [moment(evt.event.startStr), moment(evt.event.endStr)];

    eventArray.forEach((e) => {
      if (
        moment(e.start).isSameOrBefore(time[0]) &&
        moment(e.end).isSameOrAfter(time[1])
      ) {
        duplicate = true;
      }

      if (time[0].isAfter(moment(e.start)) && time[0].isBefore(moment(e.end))) {
        duplicate = true;
      }

      if (time[1].isAfter(moment(e.start)) && time[1].isBefore(moment(e.end))) {
        duplicate = true;
      }

      if (
        time[0].isSameOrBefore(moment(e.start)) &&
        time[1].isSameOrAfter(moment(e.end))
      ) {
        duplicate = true;
      }
    });

    if (!duplicate) {
      axios
        .post(URL_STRING + "/update", data)
        .then((res) => console.log(res.data));
      openNotificationWithIcon("success", "Success", "Event has been changed");
    } else {
      evt.revert();
      openNotificationWithIcon(
        "error",
        "Error",
        "This court is already booked at this particular time"
      );
    }
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b style={{ color: "black" }}>{eventInfo.timeText}</b>
        <p style={{ color: "black", marginBottom: 0, paddingLeft: "0.2rem" }}>
          <span
            style={{
              width: 0,
              height: 0,
              border: "5px solid black",
              borderColor:
                eventInfo.event.extendedProps.status === 0
                  ? "#cc9900"
                  : eventInfo.event.extendedProps.status === 1
                  ? "#0033ff"
                  : eventInfo.event.extendedProps.status === 2
                  ? "rgb(42, 133, 3)"
                  : "transparent",
              display: "inline-block",
              boxSizing: "content-box",
              borderRadius: "10px",
            }}
          ></span>{" "}
          {eventInfo.event.title}
        </p>
      </>
    );
  }

  return (
    <Row
      style={{
        margin: "0 auto",
        padding: "2rem",
      }}
    >
      <BookingInfo
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        setShowEditEventModal={setShowEditEventModal}
        setShowDeleteModal={setShowDeleteModal}
        eventInfoData={eventInfoData}
      />
      <EditBooking
        setShowEditEventModal={setShowEditEventModal}
        setShowInfoModal={setShowInfoModal}
        showEditEventModal={showEditEventModal}
        eventInfo={eventInfo}
        fetchData={fetchData}
        currentEvents={currentEvents}
      />
      <Modal
        title="Delete Event"
        width={500}
        centered
        visible={showDeleteModal}
        onOk={() => {
          axios
            .post(URL_STRING + "/delete", {
              id: eventInfo.event.id,
            })
            .then((res) => {
              fetchData();
            });
          setShowDeleteModal(false);
          setShowInfoModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
        okText="OK"
        cancelText="Cancel"
      >
        <p>{deleteModalText}</p>
      </Modal>
      <AddBooking
        setShowAddModal={setShowAddModal}
        data={addModalInfo}
        showAddModal={showAddModal}
        fetchData={fetchData}
        currentEvents={currentEvents}
      />
      <Col span={24}>
        <div className="demo-app">
          {renderSidebar()}
          <div className="demo-app-main">
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                right: "prev,next today",
                center: "title",
                left: "listMonth,timeGridDay,timeGridWeek,dayGridMonth",
              }}
              initialView="listMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
              events={currentEvents}
              select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventChange={eventOnDrag}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DemoApp;
