import React from "react";
import { Row, Col } from "antd";
import EnergyCount from "../../components/Dashboard/EnergyCount";
import List from "../../components/Dashboard/List";
import MiniBar from "../../components/Dashboard/MiniBar";
import SoccerField from "../../components/Dashboard/SoccerField";
import Map from "../../components/Map";

function Dashboard() {
  let width = window.innerWidth;
  let mediumScreen = false;

  if (width > 767) {
    mediumScreen = true;
  }

  return (
    <div
      className="site-layout-background"
      style={{
        margin: "3rem auto",
        width: "90%",
      }}
    >
      <Row
        style={{
          padding: "1rem 0.2rem",
          backgroundColor: "#fbfbfb",
          border: "1px solid #d9d9d9",
          borderRadius: "2px",
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <h2>Energy Monitoring of Tennis Court</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={24}>
          <List />
        </Col>
        <Col md={18} xs={24}>
          <EnergyCount />
        </Col>
      </Row>
      <Row
        style={{
          padding: "1rem 0.2rem",
          backgroundColor: "#fbfbfb",
          border: "1px solid #d9d9d9",
          borderRadius: "2px",
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <h2>People Counting</h2>
        </Col>
      </Row>
      <Row
        style={{
          padding: mediumScreen ? "20px" : "0px",
          paddingBottom: "40px",
        }}
      >
        <Col
          md={24}
          xs={24}
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "1.2rem",
          }}
        >
          Soccer Field
        </Col>
        <Col
          md={6}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Map />
        </Col>
        <Col md={18} xs={24}>
          <SoccerField />
        </Col>
        <Col
          md={24}
          xs={24}
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.2rem",
            fontWeight: 600,
          }}
        >
          Mini Bar
        </Col>
        <Col
          md={6}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Map />
        </Col>
        <Col md={18} xs={24}>
          <MiniBar />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
