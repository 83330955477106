import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  Modal,
  notification,
  Radio,
  Space,
} from "antd";
import moment from "moment";
import axios from "axios";
import URL_STRING from "../../mockData/data";

function EditBooking({
  eventInfo,
  setShowInfoModal,
  setShowEditEventModal,
  showEditEventModal,
  currentEvents,
  fetchData,
}) {
  const [id, setID] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState([]);
  const [date, setDate] = useState(moment());
  const [court, setCourt] = useState("");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setID(eventInfo?.event?.id);
    setName(eventInfo?.event?.title);
    setEmail(eventInfo?.event?.extendedProps.email);
    setPhone(eventInfo?.event?.extendedProps.phone);
    setCourt(eventInfo?.event?.extendedProps.cid);
    setStatus(eventInfo?.event?.extendedProps.status);
    setTime([
      moment(eventInfo?.event?.startStr.split("+")[0]),
      moment(eventInfo?.event?.endStr.split("+")[0]),
    ]);
    setDate(moment(eventInfo?.event?.startStr.split("+")[0]));
  }, [eventInfo, showEditEventModal]);

  const eventData = useSelector((state) => state.eventData);

  function onChangeTime(time) {
    if (date) {
      const timeArray = [];
      time.forEach((t) => {
        timeArray.push(t.date(date.date()));
      });
      setTime(timeArray);
    } else {
      setTime(time);
    }
  }

  function onChangeDate(date) {
    if (time.length > 0) {
      const timeArray = [];

      time.forEach((t) => {
        timeArray.push(t.date(date.date()));
      });
      setTime(timeArray);
    }
    setDate(date);
  }

  const format = "HH:mm";

  const dispatch = useDispatch();

  const submitDataEvent = () => {
    const data = {
      id: id,
      title: name,
      email,
      phone,
      date: date.format("YYYY-MM-DD"),
      start: date.format("YYYY-MM-DD") + `T${time[0].format("HH:mm")}:00`,
      end: date.format("YYYY-MM-DD") + `T${time[1].format("HH:mm")}:00`,
      className: court,
      cid: court,
      status,
    };

    const eventArray = [];

    currentEvents.forEach((e) => {
      if (
        e.cid === court &&
        e.date === date.format("YYYY-MM-DD") &&
        e.id !== parseInt(id)
      ) {
        eventArray.push(e);
      }
    });

    let duplicate = false;

    eventArray.forEach((e) => {
      if (
        moment(e.start).isSameOrBefore(time[0]) &&
        moment(e.end).isSameOrAfter(time[1])
      ) {
        duplicate = true;
      }

      if (time[0].isAfter(moment(e.start)) && time[0].isBefore(moment(e.end))) {
        duplicate = true;
      }

      if (time[1].isAfter(moment(e.start)) && time[1].isBefore(moment(e.end))) {
        duplicate = true;
      }

      if (
        time[0].isSameOrBefore(moment(e.start)) &&
        time[1].isSameOrAfter(moment(e.end))
      ) {
        duplicate = true;
      }
    });

    if (!duplicate) {
      // dispatch(updateData(data));
      axios.post(URL_STRING + "/update", data).then((res) => {
        console.log(res.data);
        fetchData();
      });
      openNotificationWithIcon("success", "Success", "Event has been changed");
    } else {
      openNotificationWithIcon(
        "error",
        "Error",
        "This court is already booked at this particular time"
      );
    }

    setShowEditEventModal(false);
    setShowInfoModal(false);
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatus(e.target.value);
  };

  return (
    <Modal
      title="Edit Event"
      width={800}
      visible={showEditEventModal}
      onCancel={() => setShowEditEventModal(false)}
      footer={[
        <Button onClick={() => setShowEditEventModal(false)} key="cancel">
          Cancel
        </Button>,
        <Button
          onClick={submitDataEvent}
          key="submit"
          type="primary"
          disabled={
            name === "" ||
            time?.length === 0 ||
            date === null ||
            email === "" ||
            phone === ""
          }
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout="horizontal"
      >
        <Form.Item label="Name">
          <Input onChange={(e) => setName(e.target.value)} value={name} />
        </Form.Item>
        <Form.Item label="Email">
          <Input onChange={(e) => setEmail(e.target.value)} value={email} />
        </Form.Item>
        <Form.Item label="Phone">
          <Input onChange={(e) => setPhone(e.target.value)} value={phone} />
        </Form.Item>
        <Form.Item label="Court / Field">
          <Select value={court} onChange={(e) => setCourt(e)}>
            <Select.Option value="court1">Court 1</Select.Option>
            <Select.Option value="court2">Court 2</Select.Option>
            <Select.Option value="court3">Court 3</Select.Option>
            <Select.Option value="court4">Court 4</Select.Option>
            <Select.Option value="court5">Field</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Time">
          <DatePicker onChange={onChangeDate} value={date} />
          <TimePicker.RangePicker
            minuteStep={30}
            showSecond={false}
            style={{ marginLeft: "2rem" }}
            format={format}
            onChange={onChangeTime}
            value={time}
          />
        </Form.Item>
        <Form.Item label="Status">
          <Radio.Group
            value={status}
            onChange={onChange}
            style={{ margin: 0, marginLeft: "1rem" }}
          >
            <Space direction="horizontal" align="start">
              <Radio value={0}>New</Radio>
              <Radio value={1}>Approved</Radio>
              <Radio value={2}>Checked In</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditBooking;
