import React from "react";
import { Layout, Breadcrumb, Tabs } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import Calendar from "../Calendar";
import { useSelector } from "react-redux";
import BookingAnalysis from "../BookingAnalysis";

function Booking() {
  const { Content } = Layout;
  const { TabPane } = Tabs;

  const history = useHistory();

  const location = useLocation();

  console.log(location.pathname.split("/"));

  const authState = useSelector((state) => state.auth);

  console.log(authState);

  return (
    <Content style={{ margin: "auto", width: "90%" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Booking</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card-container">
        <Tabs
          type="card"
          activeKey={location.pathname.split("/")[1]}
          style={{ margin: "auto" }}
          onChange={(key) => {
            history.push(`/${key}`);
          }}
        >
          <TabPane tab="Calendar" key="calendar">
            <div className="site-layout-background">
              <Calendar />
            </div>
          </TabPane>
          {authState && authState.user && authState.user.role === "admin" && (
            <TabPane tab="Booking Analysis" key="booking-analysis">
              <div
                className="site-layout-background"
                style={{
                  margin: "auto",
                  width: "100%",
                  minHeight: "100vh",
                }}
              >
                <BookingAnalysis />
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    </Content>
  );
}

export default Booking;
