import React, { useState, useEffect } from "react";
import { Row, Col, DatePicker, Button, Input, notification } from "antd";
import moment from "moment";
import styles from "./Energy.module.css";
import axios from "axios";
import URL_STRING from "../../mockData/data";

function Energy() {
  const [chosenMonth, setChosenMonth] = useState(
    moment().subtract(1, "month").format("YYYY-MM")
  );

  const [lastYearConsumption, setLastYearConsumption] = useState(0);
  const [thisYearConsumption, setThisYearConsumption] = useState(0);
  const [energyConsumption, setEnergyConsumption] = useState(8000);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [undefinedDate, setUndefinedDate] = useState(false);

  function onChangeWeek(date, dateString) {
    console.log(date);
    setChosenMonth(date.format("YYYY-MM"));
  }

  useEffect(() => {
    fetchData();
    setEnableSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenMonth]);

  const fetchData = () => {
    axios
      .get(URL_STRING + "/energy")
      // .get("http://localhost:5000/energy")
      .then((res) => {
        console.log(res.data);
        console.log(chosenMonth);
        let date = res.data.find((x) => x.date === chosenMonth);
        console.log("date", date);
        if (date === undefined) {
          console.log(
            moment(chosenMonth).subtract(1, "year").format("YYYY-MM")
          );
          date = res.data.find(
            (x) =>
              x.date ===
              moment(chosenMonth).subtract(1, "year").format("YYYY-MM")
          );
          setLastYearConsumption(date?.energyOnBillThisYear || 0);
          setThisYearConsumption(0);
          setEnergyConsumption(0);
          setUndefinedDate(true);
        } else {
          setLastYearConsumption(date?.energyOnBillLastYear || 0);
          setThisYearConsumption(date?.energyOnBillThisYear || 0);
          setEnergyConsumption(date?.energyOnDevice || 0);
          setUndefinedDate(false);
        }
      });
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const submitData = () => {
    let data;

    if (!undefinedDate) {
      data = {
        energyOnBillThisYear: thisYearConsumption,
        energyOnBillLastYear: lastYearConsumption,
        date: chosenMonth,
        monthlyPercentage:
          lastYearConsumption !== 0
            ? (thisYearConsumption / lastYearConsumption) * 100
            : 0,
        monthlySaving: thisYearConsumption - energyConsumption,
        dateBefore: moment(chosenMonth, "YYYY-MM")
          .subtract(1, "year")
          .format("YYYY-MM"),
        dateAfter: moment(chosenMonth, "YYYY-MM")
          .add(1, "year")
          .format("YYYY-MM"),
      };

      axios
        .post(
          // "http://localhost:5000/energy/update",
          URL_STRING + "/energy/update",
          data
        )
        .then((res) => {
          console.log(res.data);
          openNotificationWithIcon(
            "success",
            "Success",
            "Data has been changed"
          );
          setEnableSubmit(false);
        });
    } else {
      data = {
        energyOnBillThisYear: thisYearConsumption,
        energyOnBillLastYear: lastYearConsumption,
        date: chosenMonth,
        energyOnDevice: energyConsumption,
        monthlyPercentage:
          lastYearConsumption !== 0
            ? (thisYearConsumption / lastYearConsumption) * 100
            : 0,
        monthlySaving: thisYearConsumption - energyConsumption,
        dateBefore: moment(chosenMonth, "YYYY-MM")
          .subtract(1, "year")
          .format("YYYY-MM"),
        dateAfter: moment(chosenMonth, "YYYY-MM")
          .add(1, "year")
          .format("YYYY-MM"),
      };

      axios
        .post(
          // "http://localhost:5000/energy/update",
          URL_STRING + "/energy",
          data
        )
        .then((res) => {
          console.log(res.data);
          openNotificationWithIcon(
            "success",
            "Success",
            "Data has been changed"
          );
          setEnableSubmit(false);
        });
    }
  };

  return (
    <div
      className="site-layout-background"
      style={{
        margin: "3rem auto",
        // maxWidth: "1000px",
        width: "90%",
      }}
    >
      <Row
        style={{
          padding: "1rem 0.2rem",
          backgroundColor: "#fbfbfb",
          border: "1px solid #d9d9d9",
          borderRadius: "2px",
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <h2>Energy</h2>
        </Col>
      </Row>

      <div className={styles.body}>
        <Row>
          <Col md={24} key={"datefrom"}>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <DatePicker
                picker="month"
                onChange={onChangeWeek}
                defaultValue={moment(chosenMonth)}
                format="MMMM YYYY"
                style={{
                  width: "100%",
                  height: "50px",
                  fontWeight: 700,
                }}
              />
            </div>
            {/* <span className={styles.infoLabel}>
              The energy cost of this month will be shown in the next month's
              bill
            </span> */}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <table className={styles.list}>
              <tbody>
                <tr>
                  <td>
                    Last year's energy consumption on the bill of this month
                  </td>
                  <td>
                    <Input
                      type="number"
                      style={{ width: 150 }}
                      suffix="KWh"
                      value={lastYearConsumption}
                      onChange={(e) => {
                        setEnableSubmit(true);
                        setLastYearConsumption(parseInt(e.target.value || 0));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    This year's energy consumption on the bill of this month
                  </td>
                  <td>
                    <Input
                      type="number"
                      style={{ width: 150 }}
                      suffix="KWh"
                      value={thisYearConsumption}
                      onChange={(e) => {
                        setThisYearConsumption(parseInt(e.target.value || 0));
                        setEnableSubmit(true);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Monthly % of energy consumption in Tennis Court</td>
                  <td>
                    <Input
                      type="number"
                      style={{ width: 150 }}
                      suffix="%"
                      value={
                        lastYearConsumption > 0
                          ? Math.ceil(
                              (thisYearConsumption / lastYearConsumption) * 100
                            )
                          : 0
                      }
                      bordered={false}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Energy consumption of tennis court this month</td>
                  <td>
                    <Input
                      type="number"
                      style={{ width: 150 }}
                      suffix="KWh"
                      value={energyConsumption}
                      bordered={false}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Energy saving this month</td>
                  <td>
                    <Input
                      type="number"
                      style={{ width: 150 }}
                      suffix="KWh"
                      value={thisYearConsumption - energyConsumption}
                      bordered={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: "right",
              padding: "35px 20px",
            }}
          >
            <Button
              type="primary"
              disabled={!enableSubmit}
              onClick={submitData}
            >
              Submit
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                margin: "0 8px",
              }}
              onClick={() => {}}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Energy;
