import React from "react";
import styles from "./forgotpassword.module.css";
import { useHistory } from "react-router-dom";
import { Typography, Form, Input, Button, Row, Col, Alert, Image } from "antd";
import { ReactComponent as LoginBanner } from "../../assets/img/login-banner.svg";
import { authService } from "../../service/auth";
import loginBackground from "../../assets/img/loginBackground.jpg";
import logo from "../../assets/img/kartax.png";

const { Title, Text } = Typography;
const Login = () => {
  const [isError, setIsError] = React.useState(false);
  const history = useHistory();

  const onSubmit = (values) => {
    setIsError(false);
    const exist = authService.isEmailExist(values.email);
    if (exist) history.push("/reset-password/d4g4gse");
    else setIsError(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleBack = () => {
    history.push("/login");
  };

  return (
    <Row
      style={{
        display: "flex",
        height: "100vh",
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Col span={24} className={styles.panel}>
        <div
          style={{
            borderRadius: 5,
            overflow: "hidden",
            margin: "auto",
            width: "80%",
            maxWidth: "500px",
          }}
        >
          <div className={styles.content}>
            <Row
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "0.5rem 1rem",
              }}
            >
              <Image src={logo} height={20} width={20} preview={false} />
              <Col style={{ marginLeft: "1rem" }}>FORGOT PASSWORD</Col>
            </Row>
            <div style={{ padding: "1rem 1.5rem 0.1rem 1.5rem" }}>
              <Text style={{ padding: "1rem 0" }} type="secondary">
                Please input your registered email.
              </Text>
              {isError && (
                <Alert
                  style={{ margin: "0.5rem 0" }}
                  message="The email is not found on our database."
                  type="error"
                />
              )}
              <Form
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                onFinishFailed={onFinishFailed}
                style={{ marginTop: "1rem" }}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>
                <Form.Item>
                  <div className={styles.buttonContainer}>
                    <Button type="link" onClick={() => history.push("/login")}>
                      Back to login
                    </Button>
                    <Button type="primary" htmlType="submit" size="large">
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
