import http from "./http";
import qs from "qs";

const api = {
  getIncidentCount: (params) =>
    http.get("incidents/counts?" + qs.stringify(params)),
  getIncidentList: (params) =>
    http.get("incidents/counts?" + qs.stringify(params)),
  getIncidentHistory: (params) => http.get("incidents?" + qs.stringify(params)),
};

export default api;
