import React from "react";
import styles from "./resetpassword.module.css";
import { useHistory, useParams } from "react-router-dom";
import { authService } from "../../service/auth";
import { Typography, Form, Input, Button, Row, Col, Alert, Image } from "antd";
import loginBackground from "../../assets/img/loginBackground.jpg";
import logo from "../../assets/img/kartax.png";
import { ReactComponent as LoginBanner } from "../../assets/img/login-banner.svg";
const { Title, Text } = Typography;

const Login = () => {
  const [isError, setIsError] = React.useState(false);
  const history = useHistory();
  const { linktoken } = useParams();

  const onSubmit = async (values) => {
    setIsError(false);
    authService
      .resetPassword(values.password, linktoken)
      .then(() => history.push("/login"))
      .catch(() => setIsError(true));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row
      style={{
        display: "flex",
        height: "100vh",
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Col span={24} className={styles.panel}>
        <div
          style={{
            borderRadius: 5,
            overflow: "hidden",
            margin: "auto",
            width: "80%",
            maxWidth: "500px",
          }}
        >
          <div className={styles.content}>
            <Row
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "0.5rem 1rem",
              }}
            >
              <Image src={logo} height={20} width={20} preview={false} />
              <Col style={{ marginLeft: "1rem" }}>RESET PASSWORD</Col>
            </Row>
            <div style={{ padding: "1rem 1.5rem 0.1rem 1.5rem" }}>
              {isError && (
                <Alert
                  style={{ margin: "0.5rem 0" }}
                  message="Username or Password not found."
                  type="error"
                />
              )}
              <Form
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="New Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                    {
                      min: 3,
                      message: "Password must be minimum 3 characters.",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Form.Item>
                  <div className={styles.buttonContainer}>
                    <Button type="primary" htmlType="submit" size="large">
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
