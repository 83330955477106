import { PERMISSIONS } from "../config/const";

const users = {
  "admin@irc.com": {
    image: "https://ui-avatars.com/api/?name=admin+kasca",
    firstName: "admin",
    lastName: "kacsa",
    email: "admin@irc.com",
    company: "karta",
    phoneNumber: "0000000000000",
    password: "123456",
    role: "admin",
    permissions: [
      PERMISSIONS.OPEN_CALENDAR,
      PERMISSIONS.OPEN_BOOKING_ANALYLSIS,
      PERMISSIONS.BOOKING_EDIT_EVENT,
      PERMISSIONS.BOOKING_DELETE_EVENT,
    ],
    projectIds: [
      "d0f4586c-fdf7-49ca-8e55-a79590b890d3",
      "c64616c9-1cbf-48d2-b62f-bc674de4a01",
    ],
  },
  "user@irc.com": {
    image: "https://ui-avatars.com/api/?name=regular+user",
    firstName: "regular",
    lastName: "user",
    email: "user@irc.com",
    company: "guest",
    phoneNumber: "0000000000000",
    password: "123456",
    role: "user",
    permissions: [
      PERMISSIONS.OPEN_CALENDAR,
      PERMISSIONS.OPEN_MENU_MAP,
      PERMISSIONS.OPEN_MENU_SUB_DEVICE,
      PERMISSIONS.OPEN_MENU_SUB_REPORT,
      PERMISSIONS.OPEN_MENU_SUB_ANALYSIS,
      PERMISSIONS.OPEN_MENU_SUB_PROFILE,
      PERMISSIONS.OPEN_MENU_DEVICE_LIST,
      PERMISSIONS.OPEN_MENU_REPORT_INCIDENT,
      PERMISSIONS.OPEN_MENU_REPORT_SAFETY_MONITOR,
      PERMISSIONS.OPEN_MENU_ANALYSIS_HEAVY_MACHINERY,
      PERMISSIONS.OPEN_MENU_ANALYSIS_SAFETY_HAT,
      PERMISSIONS.OPEN_MENU_PROFILE,
      PERMISSIONS.OPEN_MENU_LOGOUT,
      PERMISSIONS.OPEN_MENU_ALERT,
      PERMISSIONS.SEE_FAILURE_LOGS,
      PERMISSIONS.SEE_DEVICE_DETAILS,
      PERMISSIONS.SEE_GRAPH_PEOPLE_COUNT,
      PERMISSIONS.SEE_GRAPH_INCIDENTS,
      PERMISSIONS.ABLE_CONFIG_MAP_DEVICE,
    ],
    projectIds: [
      "d0f4586c-fdf7-49ca-8e55-a79590b890d3",
      "c64616c9-1cbf-48d2-b62f-bc674de4a01",
    ],
  },
};

export default users;
