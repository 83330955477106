const weeklyDataEnergyCount = {
  type: "day",
  peopleInCount: {
    data: [
      { name: "Energy Consumption (kwh)", date: "10-3-2021", count: 7 },
      { name: "Energy Consumption (kwh)", date: "11-3-2021", count: 4 },
      { name: "Energy Consumption (kwh)", date: "12-3-2021", count: 3.5 },
      { name: "Energy Consumption (kwh)", date: "13-3-2021", count: 5 },
      { name: "Energy Consumption (kwh)", date: "14-3-2021", count: 5 },
      { name: "Energy Consumption (kwh)", date: "15-3-2021" },
      { name: "Energy Consumption (kwh)", date: "16-3-2021" },
    ],
  },
  peopleOutCount: {
    data: [
      { name: "Actual Energy Used (kwh)", date: "10-3-2021", count: 4 },
      { name: "Actual Energy Used (kwh)", date: "11-3-2021", count: 7 },
      { name: "Actual Energy Used (kwh)", date: "12-3-2021", count: 6 },
      { name: "Actual Energy Used (kwh)", date: "13-3-2021", count: 3 },
      { name: "Actual Energy Used (kwh)", date: "14-3-2021", count: 8 },
      { name: "Actual Energy Used (kwh)", date: "15-3-2021" },
      { name: "Actual Energy Used (kwh)", date: "16-3-2021" },
    ],
  },
};

const monthlyDataEnergyCount = {
  type: "week",
  peopleInCount: {
    data: [

      { name: "Energy Consumption (kwh)", date: "2021-1-7", count: 75 },
      { name: "Energy Consumption (kwh)", date: "2021-1-14", count: 88 },
      { name: "Energy Consumption (kwh)", date: "2021-1-21", count: 15 },
      { name: "Energy Consumption (kwh)", date: "2021-1-28", count: 37  },

      { name: "Energy Consumption (kwh)", date: "2021-2-7", count: 88 },
      { name: "Energy Consumption (kwh)", date: "2021-2-14", count: 75 },
      { name: "Energy Consumption (kwh)", date: "2021-2-21", count: 37 },
      { name: "Energy Consumption (kwh)", date: "2021-2-28", count: 15  },
      
      { name: "Energy Consumption (kwh)", date: "2021-3-7", count: 15  },
      { name: "Energy Consumption (kwh)", date: "2021-3-14", count: 88 },
      { name: "Energy Consumption (kwh)", date: "2021-3-21", count: 75 },
      { name: "Energy Consumption (kwh)", date: "2021-3-28", count: 37  },

      { name: "Energy Consumption (kwh)", date: "2021-4-7", count: 15 },
      { name: "Energy Consumption (kwh)", date: "2021-4-14", count: 37 },
      { name: "Energy Consumption (kwh)", date: "2021-4-21", count: 76 },
      { name: "Energy Consumption (kwh)", date: "2021-4-28", count: 88  },

      { name: "Energy Consumption (kwh)", date: "2021-5-7", count: 75 },
      { name: "Energy Consumption (kwh)", date: "2021-5-14"},
      { name: "Energy Consumption (kwh)", date: "2021-5-21"},
      { name: "Energy Consumption (kwh)", date: "2021-5-28"},

      { name: "Energy Consumption (kwh)", date: "2021-6-7"},
      { name: "Energy Consumption (kwh)", date: "2021-6-14"},
      { name: "Energy Consumption (kwh)", date: "2021-6-21"},
      { name: "Energy Consumption (kwh)", date: "2021-6-28"},

      { name: "Energy Consumption (kwh)", date: "2021-7-7"},
      { name: "Energy Consumption (kwh)", date: "2021-7-14"},
      { name: "Energy Consumption (kwh)", date: "2021-7-21"},
      { name: "Energy Consumption (kwh)", date: "2021-7-28"},

      { name: "Energy Consumption (kwh)", date: "2021-8-7"},
      { name: "Energy Consumption (kwh)", date: "2021-8-14"},
      { name: "Energy Consumption (kwh)", date: "2021-8-21"},
      { name: "Energy Consumption (kwh)", date: "2021-8-28"},

      { name: "Energy Consumption (kwh)", date: "2021-9-7"},
      { name: "Energy Consumption (kwh)", date: "2021-9-14"},
      { name: "Energy Consumption (kwh)", date: "2021-9-21"},
      { name: "Energy Consumption (kwh)", date: "2021-9-28"},

      { name: "Energy Consumption (kwh)", date: "2021-10-7"},
      { name: "Energy Consumption (kwh)", date: "2021-10-14"},
      { name: "Energy Consumption (kwh)", date: "2021-10-21"},
      { name: "Energy Consumption (kwh)", date: "2021-10-28"},

      { name: "Energy Consumption (kwh)", date: "2021-11-7"},
      { name: "Energy Consumption (kwh)", date: "2021-11-14"},
      { name: "Energy Consumption (kwh)", date: "2021-11-21"},
      { name: "Energy Consumption (kwh)", date: "2021-11-28"},

      { name: "Energy Consumption (kwh)", date: "2021-12-7"},
      { name: "Energy Consumption (kwh)", date: "2021-12-14"},
      { name: "Energy Consumption (kwh)", date: "2021-12-21"},
      { name: "Energy Consumption (kwh)", date: "2021-12-28"},
    ],
  },
  peopleOutCount: {
    data: [
      { name: "Actual Energy Used (kwh)", date: "2021-1-7", count: 95 },
      { name: "Actual Energy Used (kwh)", date: "2021-1-14", count: 75 },
      { name: "Actual Energy Used (kwh)", date: "2021-1-21", count: 20 },
      { name: "Actual Energy Used (kwh)", date: "2021-1-28", count: 57 },

      { name: "Actual Energy Used (kwh)", date: "2021-2-7", count: 95 },
      { name: "Actual Energy Used (kwh)", date: "2021-2-14", count: 75 },
      { name: "Actual Energy Used (kwh)", date: "2021-2-21", count: 20 },
      { name: "Actual Energy Used (kwh)", date: "2021-2-28", count: 57 },

      { name: "Actual Energy Used (kwh)", date: "2021-3-7", count: 95 },
      { name: "Actual Energy Used (kwh)", date: "2021-3-14", count: 75 },
      { name: "Actual Energy Used (kwh)", date: "2021-3-21", count: 20 },

      { name: "Actual Energy Used (kwh)", date: "2021-4-7", count: 75 },
      { name: "Actual Energy Used (kwh)", date: "2021-4-14", count: 88 },
      { name: "Actual Energy Used (kwh)", date: "2021-4-21", count: 15 },
      { name: "Actual Energy Used (kwh)", date: "2021-4-28", count: 37  },

      { name: "Actual Energy Used (kwh)", date: "2021-5-7", count: 75 },
    ],
  },
};

const yearlyDataEnergyCount = {
  type: "date",
  peopleInCount: {
    data: [
      { name: "Energy Consumption (kwh)", date: "2021-1-1", count: 488 },
      { name: "Energy Consumption (kwh)", date: "2021-2-1", count: 512 },
      { name: "Energy Consumption (kwh)", date: "2021-3-1", count: 204 },
      { name: "Energy Consumption (kwh)", date: "2021-4-1", count: 354 },
      { name: "Energy Consumption (kwh)", date: "2021-5-1", count: 404 },
      { name: "Energy Consumption (kwh)", date: "2021-6-1" },
      { name: "Energy Consumption (kwh)", date: "2021-7-1" },
      { name: "Energy Consumption (kwh)", date: "2021-8-1" },
      { name: "Energy Consumption (kwh)", date: "2021-9-1" },
      { name: "Energy Consumption (kwh)", date: "2021-10-1" },
      { name: "Energy Consumption (kwh)", date: "2021-11-1" },
      { name: "Energy Consumption (kwh)", date: "2021-12-1" },
    ],
  },
  peopleOutCount: {
    data: [
      { name: "Actual Energy Used (kwh)", date: "2021-1-1", count: 475 },
      { name: "Actual Energy Used (kwh)", date: "2021-2-1", count: 499 },
      { name: "Actual Energy Used (kwh)", date: "2021-3-1", count: 232 },
      { name: "Actual Energy Used (kwh)", date: "2021-4-1", count: 156  },
      { name: "Actual Energy Used (kwh)", date: "2021-5-1", count: 700  },
      { name: "Actual Energy Used (kwh)", date: "2021-6-1" },
      { name: "Actual Energy Used (kwh)", date: "2021-7-1" },
      { name: "Actual Energy Used (kwh)", date: "2021-8-1" },
      { name: "Actual Energy Used (kwh)", date: "2021-9-1" },
      { name: "Actual Energy Used (kwh)", date: "2021-10-1" },
      { name: "Actual Energy Used (kwh)", date: "2021-11-1" },
      { name: "Actual Energy Used (kwh)", date: "2021-12-1" },
    ],
  },
};

const reportDataEnergyCount = {
  yearlyDataEnergyCount,
  weeklyDataEnergyCount,
  monthlyDataEnergyCount,
};

export default reportDataEnergyCount;
