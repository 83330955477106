import React, { useState, useEffect } from "react";
import { Row, Col, Slider, notification } from "antd";
import axios from "axios";

import styles from "./light.module.css";
import tenniscourt from "../../assets/img/tenniscourt.jpg";

const marks = {
  0: "0",
  20: "20",
  100: "100",
};

function Light() {
  const [lightTennisCourt1, setLightTennisCourt1] = useState(0);
  const [lightTennisCourt1Changed, setLightTennisCourt1Changed] =
    useState(false);
  const [lightTennisCourt2, setLightTennisCourt2] = useState(0);
  const [lightTennisCourt2Changed, setLightTennisCourt2Changed] =
    useState(false);
  const [lightTennisCourt3, setLightTennisCourt3] = useState(0);
  const [lightTennisCourt3Changed, setLightTennisCourt3Changed] =
    useState(false);
  const [lightTennisCourt4, setLightTennisCourt4] = useState(0);
  const [lightTennisCourt4Changed, setLightTennisCourt4Changed] =
    useState(false);

  const lightCalculator = (val) => {
    switch (val) {
      case 0:
        return 0.2;

      case 20:
        return 0.4;

      case 100:
        return 1;

      default:
        break;
    }
  };

  useEffect(() => {
    axios
      .get(
        "https://sl.karta-x.net/lightings/projects/c64616c9-1cbf-48d2-b62f-bc674de4a01a"
      )
      .then((res) => {
        console.log(res.data);
        setLightTennisCourt1(res.data[0].lampLevel);
        setLightTennisCourt2(res.data[1].lampLevel);
        setLightTennisCourt3(res.data[2].lampLevel);
        setLightTennisCourt4(res.data[3].lampLevel);
      });
  }, []);

  const openNotificationWithIcon = (type, court) => {
    notification[type]({
      message: "Success",
      description: `${court} lamp level has been updated successfully.`,
    });
  };

  return (
    <div
      className="site-layout-background"
      style={{
        margin: "3rem auto",
        width: "90%",
        minHeight: "100vh",
      }}
    >
      <Row
        style={{
          padding: "1rem 0.2rem",
          backgroundColor: "#fbfbfb",
          border: "1px solid #d9d9d9",
          borderRadius: "2px",
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <h2>Light</h2>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <div className={styles.tennisCourtContainer}>
            <div className={styles.tennisCourtContainerTitle}>
              Tennis Court 1
            </div>
            <div className={styles.tennisCourtContainerStatus}>
              Status: {lightTennisCourt1 > 0 ? "on" : "off"}
            </div>
            <div className={styles.tennisCourtContainerImage__wrapper}>
              <img
                src={tenniscourt}
                alt=""
                style={{ opacity: lightCalculator(lightTennisCourt1) }}
              />
            </div>
            <div className={styles.tennisCourtContainerSlider__wrapper}>
              <div className={styles.tennisCourtContainerSlider}>
                <Slider
                  marks={marks}
                  step={null}
                  defaultValue={lightTennisCourt1}
                  value={lightTennisCourt1}
                  onChange={(e) => {
                    setLightTennisCourt1Changed(true);
                    setLightTennisCourt1(e);
                  }}
                  onAfterChange={(e) => {
                    if (lightTennisCourt1Changed) {
                      axios
                        .post(
                          "https://sl.karta-x.net/lightings/projects/c64616c9-1cbf-48d2-b62f-bc674de4a01a/groups/E90B1A01-6029-4828-8490-367698E7A609/lamplevel",
                          { lampLevel: e },
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          openNotificationWithIcon("success", "Tennis Court 1");
                          setLightTennisCourt1Changed(false);
                          console.log("call2");
                          console.log(res.data);
                        });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className={styles.tennisCourtContainer}>
            <div className={styles.tennisCourtContainerTitle}>
              Tennis Court 2
            </div>
            <div className={styles.tennisCourtContainerStatus}>
              Status: {lightTennisCourt2 > 0 ? "on" : "off"}
            </div>
            <div className={styles.tennisCourtContainerImage__wrapper}>
              <img
                src={tenniscourt}
                alt=""
                style={{ opacity: lightCalculator(lightTennisCourt2) }}
              />
            </div>
            <div className={styles.tennisCourtContainerSlider__wrapper}>
              <div className={styles.tennisCourtContainerSlider}>
                <Slider
                  marks={marks}
                  step={null}
                  defaultValue={lightTennisCourt2}
                  value={lightTennisCourt2}
                  onChange={(e) => {
                    setLightTennisCourt2Changed(true);
                    setLightTennisCourt2(e);
                  }}
                  onAfterChange={(e) => {
                    if (lightTennisCourt2Changed) {
                      axios
                        .post(
                          "https://sl.karta-x.net/lightings/projects/c64616c9-1cbf-48d2-b62f-bc674de4a01a/groups/2D930CDB-C27D-4931-B8E2-039BAA66A85B/lamplevel",
                          { lampLevel: e },
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          openNotificationWithIcon("success", "Tennis Court 2");
                          setLightTennisCourt2Changed(false);
                          console.log("call2");
                          console.log(res.data);
                        });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className={styles.tennisCourtContainer}>
            <div className={styles.tennisCourtContainerTitle}>
              Tennis Court 3
            </div>
            <div className={styles.tennisCourtContainerStatus}>
              Status: {lightTennisCourt3 > 0 ? "on" : "off"}
            </div>
            <div className={styles.tennisCourtContainerImage__wrapper}>
              <img
                src={tenniscourt}
                alt=""
                style={{ opacity: lightCalculator(lightTennisCourt3) }}
              />
            </div>
            <div className={styles.tennisCourtContainerSlider__wrapper}>
              <div className={styles.tennisCourtContainerSlider}>
                <Slider
                  marks={marks}
                  step={null}
                  value={lightTennisCourt3}
                  defaultValue={lightTennisCourt3}
                  onChange={(e) => {
                    setLightTennisCourt3Changed(true);
                    setLightTennisCourt3(e);
                  }}
                  onAfterChange={(e) => {
                    if (lightTennisCourt3Changed) {
                      axios
                        .post(
                          "https://sl.karta-x.net/lightings/projects/c64616c9-1cbf-48d2-b62f-bc674de4a01a/groups/E79B6F92-6AEA-4AD2-873C-4BA171AB879D/lamplevel",
                          { lampLevel: e },
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          openNotificationWithIcon("success", "Tennis Court 3");
                          setLightTennisCourt3Changed(false);
                          console.log("call2");
                          console.log(res.data);
                        });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className={styles.tennisCourtContainer}>
            <div className={styles.tennisCourtContainerTitle}>
              Tennis Court 4
            </div>
            <div className={styles.tennisCourtContainerStatus}>
              Status: {lightTennisCourt4 > 0 ? "on" : "off"}
            </div>
            <div className={styles.tennisCourtContainerImage__wrapper}>
              <img
                src={tenniscourt}
                alt=""
                style={{ opacity: lightCalculator(lightTennisCourt4) }}
              />
            </div>
            <div className={styles.tennisCourtContainerSlider__wrapper}>
              <div className={styles.tennisCourtContainerSlider}>
                <Slider
                  marks={marks}
                  step={null}
                  value={lightTennisCourt4}
                  defaultValue={lightTennisCourt4}
                  onChange={(e) => {
                    setLightTennisCourt4(e);
                    setLightTennisCourt4Changed(true);
                  }}
                  onAfterChange={(e) => {
                    if (lightTennisCourt4Changed) {
                      axios
                        .post(
                          "https://sl.karta-x.net/lightings/projects/c64616c9-1cbf-48d2-b62f-bc674de4a01a/groups/E4E1AB83-E51A-4880-89D0-F36950578830/lamplevel",
                          { lampLevel: e },
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          openNotificationWithIcon("success", "Tennis Court 4");
                          setLightTennisCourt4Changed(false);
                          console.log("call2");
                          console.log(res.data);
                        });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Light;
