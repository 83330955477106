import { combineReducers } from "redux";
import AuthReducer from "./auth";
import EnergyReducer from "./energy";
import PeopleCountReducer from "./peopleCount";
import MinibarCountReducer from "./miniBarCount";

const RootReducer = combineReducers({
  auth: AuthReducer,
  energy: EnergyReducer,
  peopleCount: PeopleCountReducer,
  minibarCount: MinibarCountReducer,
});

export default RootReducer;
