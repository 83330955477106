import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  DashboardOutlined,
  BulbOutlined,
  CalendarOutlined,
  ThunderboltFilled,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

function LeftMenu(props) {
  const authState = useSelector((state) => state.auth);

  const { SubMenu } = Menu;

  return (
    <Menu theme={"dark"} mode={props.mode}>
      {authState && authState.user && authState.user.role === "admin" && (
        <>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="energy" icon={<ThunderboltFilled />}>
            <Link to="/energy">Energy</Link>
          </Menu.Item>
          <Menu.Item key="Light" icon={<BulbOutlined />}>
            <Link to="/light">Light</Link>
          </Menu.Item>

          <SubMenu title="Booking" icon={<CalendarOutlined />}>
            <Menu.Item key="calendar">
              <Link to="/calendar">Calendar</Link>
            </Menu.Item>
            <Menu.Item key="booking-analysis">
              <Link to="/booking-analysis">Analysis</Link>
            </Menu.Item>
          </SubMenu>
        </>
      )}
      {authState && authState.user && authState.user.role === "user" && (
        <>
          <SubMenu title="Booking" icon={<CalendarOutlined />}>
            <Menu.Item key="calendar">
              <Link to="/calendar">Calendar</Link>
            </Menu.Item>
          </SubMenu>
        </>
      )}
    </Menu>
  );
}

export default LeftMenu;
