export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_ENERGY = "SET_ENERGY";

export const ADD_DATA = "ADD_DATA";
export const SET_DATA = "SET_DATA";
export const UPDATE_DATA = "UPDATE_DATA";
export const DELETE_DATA = "DELETE_DATA";

export const GET_INCIDENT_COUNT_PENDING = "GET_INCIDENT_COUNT_PENDING";
export const GET_INCIDENT_COUNT_SUCCESS = "GET_INCIDENT_COUNT_SUCCESS";
export const GET_INCIDENT_COUNT_ERROR = "GET_INCIDENT_COUNT_ERROR";

export const GET_PEOPLE_COUNT_PENDING = "GET_PEOPLE_COUNT_PENDING";
export const GET_PEOPLE_COUNT_SUCCESS = "GET_PEOPLE_COUNT_SUCCESS";
export const GET_PEOPLE_COUNT_ERROR = "GET_PEOPLE_COUNT_ERROR";

export const GET_MINIBAR_COUNT_PENDING = "GET_MINIBAR_COUNT_PENDING";
export const GET_MINIBAR_COUNT_SUCCESS = "GET_MINIBAR_COUNT_SUCCESS";
export const GET_MINIBAR_COUNT_ERROR = "GET_MINIBAR_COUNT_ERROR";

export const GET_INCIDENT_LIST_PENDING = "GET_INCIDENT_LIST_PENDING";
export const GET_INCIDENT_LIST_SUCCESS = "GET_INCIDENT_LIST_SUCCESS";
export const GET_INCIDENT_LIST_ERROR = "GET_INCIDENT_LIST_ERROR";
