import moment from "moment";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const paramsBuilder = (dateType, dateValue, projectId) => {
  let dateFrom;
  let dateTo;
  let dateInterval;

  if (dateType === "day") {
    dateInterval = "Hourly";
    dateFrom = moment(dateValue).format("YYYY-MM-DD");
    dateTo = moment(dateValue).format("YYYY-MM-DD");
  }

  if (dateType === "week") {
    dateInterval = "Daily";
    let d = moment(moment(dateValue).format("YYYY-MM-DD")).startOf("week");
    dateFrom = moment(d).format("YYYY-MM-DD");
    dateTo = moment(d).add(6, "d").format("YYYY-MM-DD");
  }

  if (dateType === "month") {
    dateInterval = "Weekly";
    dateFrom = moment(dateValue).startOf("month").format("YYYY-MM-DD");
    dateTo = moment(dateValue).endOf("month").format("YYYY-MM-DD");
  }

  if (dateType === "year") {
    dateInterval = "Monthly";
    dateFrom = moment(dateValue).startOf("year").format("YYYY-MM-DD");
    dateTo = moment(dateValue).endOf("year").format("YYYY-MM-DD");
  }

  return {
    projectId: projectId,
    dateInterval: dateInterval,
    dateFrom: dateFrom,
    dateTo: dateTo,
  };
};

export const getNames = (data) => {
  let names = [];

  data.forEach((e) => {
    if (!names.includes(e.name)) {
      names.push(e.name);
    }
  });
  return names.length > 0 ? names : [""];
};

export const generateDefGraph = (data, graphTime, dateValue) => {
  let dt = [];

  let names = getNames(data);

  if (graphTime === "week") {
    let d = moment(moment(dateValue).format("YYYY-MM-DD")).startOf("week");
    for (const name of names) {
      for (let index = 0; index < 7; index++) {
        dt.push({
          name: name,
          date: moment(d).add(index, "d").format("YYYY-MM-DD"),
          count: undefined,
        });
      }
    }
  }

  if (graphTime === "month") {
    let days = moment(dateValue).daysInMonth();
    let yyyy = moment(dateValue).format("YYYY");
    let mm = moment(dateValue).format("MM");
    for (const name of names) {
      for (let index = 1; index <= days; index++) {
        let idx = index >= 10 ? index : "0" + index;
        let nd = yyyy + "-" + mm + "-" + idx;
        let dn = moment(nd).format("dddd");
        if (dn === "Sunday") {
          dt.push({
            name: name,
            date: nd,
            count: undefined,
          });
        }
      }
    }

    // const countBar = moment(dateValue).daysInMonth() > 28 ? 5 : 4;
    // for (const name of names) {
    //   for (let index = 1; index <= countBar; index++) {
    //     dt.push({
    //       name: name,
    //       date: "week-" + index,
    //       count: undefined,
    //     });
    //   }
    // }
  }

  if (graphTime === "year") {
    for (const name of names) {
      for (const m of months) {
        dt.push({
          name: name,
          date: m,
          count: undefined,
        });
      }
    }
  }

  for (const item of data) {
    const s = dt.findIndex((x) => x.name === item.name && x.date === item.date);
    if (s && dt[s]) {
      dt[s].count = item.count;
    } else {
      dt.push(item);
    }
  }

  return dt;
};

export const generateData = (
  data = [],
  graphTime = "week",
  isEqual = false,
  incidentNames = []
) => {
  let dt = [];

  for (const item of data) {
    let label = moment.utc(item.incidentDate).format("YYYY-MM-DD");

    if (graphTime === "day") {
      label = moment.utc(item.incidentDate).format("HH:mm");
    }
    if (graphTime === "month") {
      //   label =
      //     "week-" +
      //     Math.ceil(parseInt(moment.utc(item.incidentDate).format("D")) / 7);
      label = moment.utc(item.incidentDate).format("YYYY-MM-DD");
    }
    if (graphTime === "year") {
      label = moment.utc(item.incidentDate).format("MMM");
    }

    if (isEqual && incidentNames.includes(item.incident)) {
      dt.push({
        name: item.incident,
        date: label,
        count: item.count,
      });
    }

    if (!isEqual && !incidentNames.includes(item.incident)) {
      dt.push({
        name: item.incident,
        date: label,
        count: item.count,
      });
    }
  }
  return dt;
};

export const getDiffDate = (dateValue) => {
  const current = moment(dateValue).format("YYYY-MM-DD");
  return moment(current).diff(moment().format("YYYY-MM-DD"), "days");
};

export const checkDateLiveUpdate = (dateValue, graphTime) => {
  const dayCheck =
    moment().format("YYYY-MM-DD") === moment(dateValue).format("YYYY-MM-DD");

  if (graphTime === "day" && dayCheck) {
    return true;
  }

  let d = moment(moment(dateValue).format("YYYY-MM-DD")).startOf("week");

  if (graphTime === "week") {
    for (let index = 0; index < 7; index++) {
      if (
        moment().format("YYYY-MM-DD") ===
        moment(d).add(index, "d").format("YYYY-MM-DD")
      ) {
        return true;
      }
    }
  }

  const monthCheck =
    moment().format("YYYY-MM") === moment(dateValue).format("YYYY-MM");
  if (graphTime === "month" && monthCheck) {
    return true;
  }

  const yearCheck =
    moment().format("YYYY") === moment(dateValue).format("YYYY");
  if (graphTime === "year" && yearCheck) {
    return true;
  }

  return false;
};

export const graphConfig = (graphType) => {
  let configCombined;
  if (graphType === "bar") {
    configCombined = {
      data: [],
      isGroup: true,
      xField: "date",
      yField: "count",
      seriesField: "name",
      label: {
        position: "middle",
        layout: [
          { type: "interval-adjust-position" },
          { type: "interval-hide-overlap" },
          { type: "adjust-color" },
        ],
      },
      //   color: function color(_ref) {
      //     var name = _ref.name;
      //     return name === "People In Count" ? "#5b8ff9" : "#5ad8a6";
      //   },
    };
  } else if (graphType === "line") {
    configCombined = {
      data: [],
      xField: "date",
      yField: "count",
      yAxis: {
        label: {
          //   formatter: function formatter(v) {
          //     return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
          //       return "".concat(s, ",");
          //     });
          //   },
        },
      },
      seriesField: "name",
      //   color: function color(_ref) {
      //     var name = _ref.name;
      //     return name === "People In Count" ? "#5b8ff9" : "#5ad8a6";
      //   },
      //   lineStyle: function lineStyle(_ref2) {
      //     var name = _ref2.name;
      //     if (name === "People In Count") {
      //       return {
      //         // lineDash: [4, 4],
      //         opacity: 1,
      //       };
      //     }
      //     return { opacity: 0.5 };
      //   },
    };
  }
  return configCombined;
};
