import { store } from "../../../store";
import { setIncidentCount } from "../../../store/actions/incidentCount";
import api from "../../../config/api";
import {
  GET_INCIDENT_COUNT_ERROR,
  GET_INCIDENT_COUNT_PENDING,
  GET_PEOPLE_COUNT_PENDING,
  GET_PEOPLE_COUNT_ERROR,
  GET_MINIBAR_COUNT_ERROR,
  GET_MINIBAR_COUNT_PENDING,
} from "../../../store/types";
import { setPeopleCount } from "../../../store/actions/peopleCount";
import { setMinibarCount } from "../../../store/actions/miniBarCount";

export const peopleCountServices = {
  getData: async (params) => {
    try {
      store.dispatch({ type: GET_PEOPLE_COUNT_PENDING });
      const ress = await api.getIncidentCount(params);
      store.dispatch(setPeopleCount(ress.data.incidentCounts));
    } catch (error) {
      store.dispatch({ type: GET_PEOPLE_COUNT_ERROR });
    }
  },
  getDataOnly: async (params, cb) => {
    api
      .getIncidentCount(params)
      .then((data) => {
        cb(data.data);
      })
      .catch(() => {
        cb(null);
      });
  },
  setData: async (data) => {
    store.dispatch(setPeopleCount(data));
  },
};

export const minibarCountServices = {
  getData: async (params) => {
    console.log("DISPATCH");
    try {
      store.dispatch({ type: GET_MINIBAR_COUNT_PENDING });
      const ress = await api.getIncidentCount(params);
      store.dispatch(setMinibarCount(ress.data.incidentCounts));
    } catch (error) {
      store.dispatch({ type: GET_MINIBAR_COUNT_ERROR });
    }
  },
  getDataOnly: async (params, cb) => {
    api
      .getIncidentCount(params)
      .then((data) => {
        cb(data.data);
      })
      .catch(() => {
        cb(null);
      });
  },
  setData: async (data) => {
    store.dispatch(setMinibarCount(data));
  },
};

export const incidentCountServices = {
  getData: async (params) => {
    try {
      store.dispatch({ type: GET_INCIDENT_COUNT_PENDING });
      const ress = await api.getIncidentCount(params);
      store.dispatch(setIncidentCount(ress.data.incidentCounts));
    } catch (error) {
      store.dispatch({ type: GET_INCIDENT_COUNT_ERROR });
    }
  },
  getDataOnly: async (params, cb) => {
    api
      .getIncidentCount(params)
      .then((data) => {
        cb(data.data);
      })
      .catch(() => {
        cb(null);
      });
  },
  setData: async (data) => {
    store.dispatch(setIncidentCount(data));
  },
};
