import { useEffect } from "react";

export const checkRoles = (roles = [], user = null) => {
  let isAllowed = false;
  roles.forEach((item) => {
    if (item === user.role) {
      isAllowed = true;
    }
  });
  return isAllowed;
};

export const checkPermissions = (pemission = [], user_pemissions = []) => {
  let isAllowed = false;
  pemission.forEach((item) => {
    if (user_pemissions.includes(item)) {
      isAllowed = true;
    }
  });
  return isAllowed;
};

export const getDefValue = (data, field, def = "") => {
  if (data && data[field]) {
    return data[field];
  }
  return def;
};

export const DocumentTitle = (title) => {
  useEffect(() => {
    document.title = "Kacsa - " + title;
  });
};
