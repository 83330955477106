import {
  GET_PEOPLE_COUNT_ERROR,
  GET_PEOPLE_COUNT_PENDING,
  GET_PEOPLE_COUNT_SUCCESS,
} from "../types";

const initialState = {
  isLoading: false,
  data: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PEOPLE_COUNT_PENDING:
      return {
        ...state,
        isLoading: true,
        data: [],
      };
    case GET_PEOPLE_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_PEOPLE_COUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default Reducer;
